<template>
  <div class="animated">
    <CustomModal
      :modalTitle="$t('delete')"
      :button1Title="$t('delete')"
      :show="removeModal"
      @hide="removeModal = false"
      @onButton1Click="remove()"
    >
      {{ $t("deleteConfirm") }}
    </CustomModal>
    <b-card no-body>
      <b-card-header>
        <i class="icon-user mr-1"></i>
        {{ $t("users") }}
      </b-card-header>
      <b-card-body>
        <v-server-table
          :columns="columns"
          ref="table"
          :options="options"
          id="dataTable"
          class="dataGrid userGrid"
        >
          <template slot="active" slot-scope="props">
            <b-badge
              size="lg"
              :variant="props.row.active ? 'success' : 'danger'"
              >{{ props.row.active ? $t("yes") : $t("no") }}</b-badge
            >
          </template>
          <template slot="isEmployee" slot-scope="props">
            <b-badge
              size="lg"
              :variant="props.row.isEmployee ? 'success' : 'danger'"
              >{{ props.row.isEmployee ? $t("yes") : $t("no") }}</b-badge
            >
          </template>
          <template slot="buttons" slot-scope="props">
            <div class="nowrap">
              <b-button
                variant="primary icon"
                v-b-tooltip.hover
                :title="$t('edit')"
                class="mr-1 btn-brand"
                v-if="$auth.hasAuth($options, 'edit')"
              >
                <i class="fa fa-edit" @click="edit(props.row.id)"></i>
              </b-button>
              <b-button
                variant="danger icon"
                v-b-tooltip.hover
                :title="$t('delete')"
                class="mr-1 btn-brand"
                v-if="$auth.hasAuth($options, 'remove')"
              >
                <i
                  class="fa fa-trash"
                  @click="showRemoveModal(props.row.id)"
                ></i>
              </b-button>
            </div>
          </template>
        </v-server-table>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import gridOptions from "../../shared/grid-options";
import moment from "moment";
import http from "../../shared/http";
import { ServerTable } from "vue-tables-2";
import utility from "../../shared/utils/screen-helper";
import toast from "../../shared/toast-helper";
import CustomModal from "../../widgets/form/CustomModal";
import UserClaim from "../../widgets/user/UserClaim";
export default {
  name: "UserTableComponent",
  components: { ServerTable, CustomModal, UserClaim },
  props: {
    filters: {
      default: {
        active: null,
        isEmployee: null,
      },
      type: Object,
      required: false,
    },
  },
  methods: {
    getList() {
      this.$refs.table.setPage(1);
    },
    showRemoveModal(id) {
      this.removeModal = true;
      this.selectedId = id;
    },
    remove() {
      http.post(`users/delete?userId=${this.selectedId}`).then((response) => {
        if (response.success) {
          this.getList();
          this.removeModal = false;
          this.selectedId = 0;
          toast.success(response.message);
        }
      });
    },
    edit(id) {
      this.$router.push({ path: `user-add/${id}` });
    },
  },
  watch: {
    filters: {
      handler() {
        this.getList();
      },
      deep: true,
    },
  },
  data() {
    return {
      removeModal: false,
      userClaimModal: false,
      selectedUserId: 0,
      columns: [
        "firstName",
        "lastName",
        "userName",
        "email",
        "active",
        "isEmployee",
        "buttons",
      ],
      options: {
        ...gridOptions.getAll(),
        perPageValues: [10, 25, 50, 100],
        requestFunction: (data) => {
          let path = "users/getlistbypaging";
          if (!data.orderBy) {
            data.orderBy = "id";
            data.ascending = 0;
          }
          if (this.filters) {
            data.active = this.filters.active?.id;
            data.isEmployee = this.filters.isEmployee?.id;
          }

          return http
            .get(path, { params: data })
            .catch(function (e) {
              this.dispatch("error", e);
            })
            .finally(() => {
              utility.scrollToTop();
            });
        },
        sortable: "firstName,lastName,userName,email",
        headings: {
          firstName: this.$t("firstName"),
          lastName: this.$t("lastName"),
          userName: this.$t("userName"),
          email: this.$t("email"),
          active: this.$t("active"),
          isEmployee: this.$t("isEmployee"),
          buttons: this.$t("operations"),
        },
        templates: {
          createdAt: (h, row) =>
            moment(row.createdAt).format("DD/MM/YYYY HH:mm"),
        },
      },
    };
  },
};
</script>