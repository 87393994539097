<template>
  <b-container>
    <div class="animated fadeIn">
      <b-form @submit.stop.prevent="onSubmit">
        <b-row>
          <b-col sm="12">
            <v-client-table
              :columns="columns"
              :data="claimList"
              :options="options"
              id="dataTable"
              ref="dataTable"
              class="dataGridRowHeight"
              v-if="$auth.hasAuth($options, 'list')"
            >
              <template slot="active" slot-scope="props">
                <CheckBox
                  v-model="props.row.isChecked"
                  :defaultValue="props.row.isChecked"
                  @input="(val) => changed(props.row)"
                ></CheckBox>
              </template>
            </v-client-table>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" class="text-right">
            <div class="form-actions">
              <BrandButton
                buttonVariant="success"
                buttonIcon="fa fa-save"
                @click="save"
                v-if="$auth.hasAuth($options, 'save')"
                :text="$t('save')"
              />
              <BrandButton
                buttonVariant="secondary"
                buttonIcon="fa fa-check-square-o"
                @click="doSelectAll(!selectAll)"
                :text="$t('selectAll')"
              />
            </div>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </b-container>
</template>

<script>
import http from "../../shared/http";
import toast from "../../shared/toast-helper";
import jwtHelper from "../../shared/jwt-helper";
import CheckBox from "../../widgets/form/CustomCheckBox";
import BrandButton from "../../widgets/form/CustomBrandButton";
import gridOptions from "../../shared/grid-options";
import { ClientTable } from "vue-tables-2";
export default {
  name: "UserClaim",
  props: {
    userId: { type: Number, required: true },
  },
  components: {
    CheckBox,
    ClientTable,
    BrandButton,
  },
  methods: {
    doSelectAll(state) {
      for (let i = 0; i < this.claimList.length; i++) {
        this.claimList[i].isChecked = state;
      }
      this.selectAll = state;
    },
    undoChanges() {
      this.claimList = [];
      this.claimList = JSON.parse(JSON.stringify(this.orgList));
      this.$refs.dataTable.resetQuery();
    },
    isChanged() {
      for (let i = 0; i < this.claimList.length; i++) {
        if (this.claimList[i].isChecked != this.orgList[i].isChecked) {
          return true;
          break;
        }
      }
      return false;
    },
    changed(val) {
      let itemIndex = this.claimList.findIndex((x) => x.id == val.id);
      this.claimList[itemIndex].isChecked = val.isChecked;
    },
    loadData() {
      this.claimList = [];
      this.getClaims();
    },
    save() {
      let path = "UserOperationClaims/update";
      let operationClaimIds = [];
      this.claimList.forEach((element) => {
        if (element.isChecked) {
          operationClaimIds.push(element.id);
        }
      });
      if (jwtHelper.getUserId() == this.userId) {
        this.$store.state.userClaims.set("");
        this.$store.state.userClaims.set(operationClaimIds);
      }
      let model = {
        id: 0,
        userId: this.userId,
        operationClaimIds: operationClaimIds,
      };
      http.post(path, model).then((result) => {
        if (result && result.success) {
          toast.success(result.message);
          this.orgList = [];
          this.orgList = JSON.parse(JSON.stringify(this.claimList));
        }
      });
    },
    getClaims() {
      let path = `UserOperationClaims/getbyuserid?userId=${this.userId}`;
      this.currentClaims = [];
      http.get(path).then((response) => {
        if (response) this.currentClaims = response;
        this.getAllClaims();
      });
    },
    getAllClaims() {
      let allClaims = [];
      http.get("OperationClaims/getlist").then((result) => {
        allClaims = result;
        allClaims.forEach((element) => {
          let isChecked =
            this.currentClaims.findIndex(
              (x) => x.operationClaimId == element.id
            ) >= 0;
          let temp = {
            id: element.id,
            title: element.claimName,
            isChecked: isChecked,
          };
          this.claimList.push(temp);
          this.orgList.push(JSON.parse(JSON.stringify(temp)));
        });
        this.isInLoading = false;
      });
      this.$refs.dataTable.resetQuery();
    },
  },
  data() {
    return {
      claimList: [],
      orgList: [],
      currentClaims: [],
      allClaims: [],
      selectAll: false,
      columns: ["title", "active"],
      options: {
        ...gridOptions.getAll(),
        debounce: 100,
        headings: {
          title: this.$t("title"),
          active: this.$t("active"),
        },
      },
    };
  },
  created() {
    this.loadData();
  },
  watch: {},
};
</script>
<style scoped>
fieldset.form-group {
  margin-bottom: 0rem !important;
}
.switch-slider ::after {
  font-size: 30px !important;
}
</style>