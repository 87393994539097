<template>
  <div class="animated">
    <b-card no-body>
      <b-card-header>
        <i class="fa fa-filter mr-1"></i>
        {{ $t("filter") }}
        <div class="card-header-actions">
          <BrandButton
            :text="$t('newUser')"
            @click="add()"
            v-if="$auth.hasAuth($options, 'add')"
          ></BrandButton>
        </div>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col md="4">
            <b-form-group>
              <label for="activeState">{{ $t("active") }}</label>
              <ActiveStateDropDown
                :activeState="filterObject.active"
                @change="activeStateChanged"
              ></ActiveStateDropDown>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label for="employeeState">{{ $t("employeeState") }}</label>
              <EmployeeStateDropDown
                :employeeState="filterObject.isEmployee"
                @change="employeeStateChanged"
              ></EmployeeStateDropDown>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <UserTableComponent
      :filters="filterObject"
      v-if="$auth.hasAuth($options, 'list')"
    ></UserTableComponent>
  </div>
</template>

<script>
import UserTableComponent from "../../widgets/user/UserTable";
import ActiveStateDropDown from "../../widgets/ActiveStateDropDown";
import EmployeeStateDropDown from "../../widgets/EmployeeStateDropDown";
import BrandButton from "../../widgets/form/CustomBrandButton";
export default {
  name: "UserList",
  components: {
    UserTableComponent,
    ActiveStateDropDown,
    BrandButton,
    EmployeeStateDropDown,
  },
  methods: {},
  data() {
    return {
      filterObject: {
        active: null,
        isEmployee: null,
      },
    };
  },
  methods: {
    activeStateChanged(activeState) {
      this.filterObject.active = activeState;
    },
    employeeStateChanged(employeeState) {
      this.filterObject.isEmployee = employeeState;
    },
    add() {
      this.$router.push({ path: "user-add" });
    },
  },
};
</script>


